import { Question } from '@shared/common';
import { FC } from 'react';

import { FUNNELS } from './constants';

export const AppRoute = {
  QUIZ: '/quiz',
  EMAIL: '/email',
  SUMMARY: '/summary',
  LOADER: '/loader',
  SHIPPING: '/shipping',
  CHECKOUT: '/checkout',
  CHECKOUT_PAGE: '/checkout-page',
  ORDER: '/order',
  PLANS: '/plans',
  ERROR: '/error',
};

export type AppQuestion = Question;

export type QuestionComp = FC<{ question: AppQuestion; onSelect: (answer: { answer: string }) => void }>;

export type Funnel = keyof typeof FUNNELS;
