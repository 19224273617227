import { Lead, LeadCreate } from '@menesko/models-harmonia';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';

import { APIClient, FunctionName, getAPIUrl } from '~/firebase-init';

import { useAppLead } from './useAppLead';
import { useTracking } from './useTracking';

type Request = LeadCreate;
type Response = Lead;

export const useLeadCreateMutation = (
  options?: Omit<UseMutationOptions<Response, AxiosError, Request, unknown>, 'mutationKey'>,
) => {
  const { emailLeft } = useTracking();
  const { updateLead } = useAppLead();

  return useMutation<Response, AxiosError, Request>(FunctionName.LEAD, {
    mutationFn: (data) => APIClient.post<Request, Response>(getAPIUrl(FunctionName.LEAD), data),
    ...options,
    onMutate: (variables) => {
      if (options?.onMutate) {
        options.onMutate(variables);
      }

      return variables;
    },
    onSuccess: (data, variables, context) => {
      // if (!variables?.id) {
      //   emailLeft({ leadId: data.id });
      // }
      emailLeft({ leadId: data.id });

      updateLead({ id: data.id, email: data.email, shippingDetails: data.shippingDetails });

      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};
