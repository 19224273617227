import { Box, Flex, Text } from '@chakra-ui/react';
import { ForwardedRef, forwardRef } from 'react';

import FreeGuideSVG from '~/assets/icons/free_guide.svg';
import MadeInUSSVG from '~/assets/icons/made_in_us.svg';
import ShippingSVG from '~/assets/icons/shipping.svg';

export const BadgesList = forwardRef((props, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <Flex {...props} ref={ref} width='100%' justifyContent='space-between' gap='0.5rem'>
      <Box>
        <Flex justifyContent='center' marginBottom='0.5rem'>
          <ShippingSVG />
        </Flex>
        <Text fontSize='12px'>Free Shipping</Text>
      </Box>
      <Box>
        <Flex justifyContent='center' marginBottom='0.5rem'>
          <MadeInUSSVG />
        </Flex>
        <Text fontSize='12px'>Harmonia is made in the USA</Text>
      </Box>
      <Box>
        <Flex justifyContent='center' marginBottom='0.5rem'>
          <FreeGuideSVG />
        </Flex>
        <Text fontSize='12px'>Scientifically proven</Text>
      </Box>
    </Flex>
  );
});

BadgesList.displayName = 'BadgesList';
