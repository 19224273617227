import { Box, Flex, Text, useTheme } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import { useEffect } from 'react';

import CheckSVG from '~/assets/icons/check.svg';
import { useAppRouter } from '~/hooks/useAppRouter';
import { AppRoute } from '~/types';

const progressAnimation = keyframes`
  from { width: 0%; }
  to { width: 100%; }
`;

const fadeInAnimation = keyframes({
  '0%': {
    opacity: 0.5,
  },
  '1%': {
    opacity: 1,
  },
  '100%': {
    opacity: 1,
  },
});

const fadeIn2Animation = keyframes({
  '0%': {
    opacity: 0.2,
  },
  '25%': {
    opacity: 0.2,
  },
  '26%': {
    opacity: 1,
  },
  '100%': {
    opacity: 1,
  },
});

const fadeIn3Animation = keyframes({
  '0%': {
    opacity: 0.2,
  },
  '60%': {
    opacity: 0.2,
  },
  '61%': {
    opacity: 1,
  },
  '100%': {
    opacity: 1,
  },
});

const fadeIn4Animation = keyframes({
  '0%': {
    opacity: 0.2,
  },
  '85%': {
    opacity: 0.2,
  },
  '86%': {
    opacity: 1,
  },
  '100%': {
    opacity: 1,
  },
});

const ANIMATION_DURATION = 7;

export const CalculationsLoader = () => {
  const { colors } = useTheme();
  const { pushNextFunnelRoute, funnelName } = useAppRouter(AppRoute.SUMMARY);

  useEffect(() => {
    const timeout = setTimeout(() => {
      pushNextFunnelRoute(funnelName === 'main' ? { path: AppRoute.PLANS } : undefined);
    }, ANIMATION_DURATION * 1000);

    return () => clearTimeout(timeout);
  }, [pushNextFunnelRoute, funnelName]);

  return (
    <Box width='100%'>
      <Text
        fontSize={{ base: '30px', md: '44px' }}
        textAlign={{ base: 'inherit', md: 'center' }}
        maxW='44rem'
        lineHeight='110%'
        fontFamily='heading'
        margin='0 auto 3.5rem auto'
      >
        Evaluating your answers...
      </Text>
      <Flex maxW='24rem' bg='quiz.progressBg' borderRadius='2rem' overflow='hidden' margin='0 auto 1.5rem auto'>
        <Flex
          height='0.75rem'
          width='0'
          bg='primary'
          animation={`${progressAnimation} ${ANIMATION_DURATION}s linear forwards`}
          transition='width 1ms'
        />
      </Flex>
      <Flex maxW='24rem' marginX='auto' flexDir='column' gap='0.5rem'>
        <Flex gap='0.5rem' alignItems='center' animation={`${fadeInAnimation} ${ANIMATION_DURATION}s linear forwards`}>
          <CheckSVG stroke={colors.primary} />
          <Text fontSize='15px' lineHeight='120%'>
            Evaluating your answers...
          </Text>
        </Flex>
        <Flex
          gap='0.5rem'
          alignItems='center'
          opacity={0.2}
          animation={`${fadeIn2Animation} ${ANIMATION_DURATION}s linear forwards`}
        >
          <CheckSVG stroke={colors.primary} />
          <Text fontSize='15px' lineHeight='120%'>
            Analyzing your results...
          </Text>
        </Flex>
        <Flex
          gap='0.5rem'
          alignItems='center'
          opacity={0.2}
          animation={`${fadeIn3Animation} ${ANIMATION_DURATION}s linear forwards`}
        >
          <CheckSVG stroke={colors.primary} />
          <Text fontSize='15px' lineHeight='120%'>
            Comparing with others...
          </Text>
        </Flex>
        <Flex
          gap='0.5rem'
          alignItems='center'
          opacity={0.2}
          animation={`${fadeIn4Animation} ${ANIMATION_DURATION}s linear forwards`}
        >
          <CheckSVG stroke={colors.primary} />
          <Text fontSize='15px' lineHeight='120%'>
            Building your summary...
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};
