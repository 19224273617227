import { Box, Flex, useBreakpointValue } from '@chakra-ui/react';
import Image from 'next/image';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PcosSlide1Image from '~/assets/images/product_slider/pcos_slide_1.png';
import PcosSlide2Image from '~/assets/images/product_slider/pcos_slide_2.png';
import PcosSlide3Image from '~/assets/images/product_slider/pcos_slide_3.png';
import PcosSlide4Image from '~/assets/images/product_slider/pcos_slide_4.png';
import PcosSlide5Image from '~/assets/images/product_slider/pcos_slide_5.png';
import PcosSlide6Image from '~/assets/images/product_slider/pcos_slide_6.png';
import Slide1Image from '~/assets/images/product_slider/slide_1.png';
import Slide2Image from '~/assets/images/product_slider/slide_2.png';
import Slide3Image from '~/assets/images/product_slider/slide_3.png';
import Slide4Image from '~/assets/images/product_slider/slide_4.png';
import Slide5Image from '~/assets/images/product_slider/slide_5.png';
import Slide6Image from '~/assets/images/product_slider/slide_6.png';
import { useAppRouter } from '~/hooks/useAppRouter';

export const ProductGallery = () => {
  const isArrowsEnabled = useBreakpointValue({ base: false, md: true });
  const { funnelName } = useAppRouter();
  const slideImages =
    funnelName === 'pcos'
      ? [PcosSlide1Image, PcosSlide2Image, PcosSlide3Image, PcosSlide4Image, PcosSlide5Image, PcosSlide6Image]
      : [Slide1Image, Slide2Image, Slide3Image, Slide4Image, Slide5Image, Slide6Image];

  return (
    <Box
      width='100%'
      sx={{
        '.slick-dots': {
          bottom: '1rem',
        },
        '.slick-dots li': {
          margin: 0,
        },
        '.slick-dots button:before': {
          fontSize: '8px',
        },
        '.slick-arrow': {
          zIndex: 10,
        },
        '.slick-arrow:before': {
          content: 'none',
        },
      }}
    >
      <Slider
        dots={true}
        arrows={isArrowsEnabled}
        nextArrow={
          <Box width='2.5rem' height='2.5rem'>
            <Flex
              width='2.5rem'
              height='2.5rem'
              bg='black'
              borderRadius='3rem'
              justifyContent='center'
              alignItems='center'
              transform='rotate(180deg)'
            >
              <ArrowSVG />
            </Flex>
          </Box>
        }
        prevArrow={
          <Box width='2.5rem' height='2.5rem'>
            <Flex
              width='2.5rem'
              height='2.5rem'
              bg='black'
              borderRadius='3rem'
              justifyContent='center'
              alignItems='center'
            >
              <ArrowSVG />
            </Flex>
          </Box>
        }
      >
        {slideImages.map((image, index) => (
          <div key={index}>
            <Flex position='relative' width='100%' paddingBottom='64%' borderRadius='0.5rem' overflow='hidden'>
              <Image src={image} fill priority placeholder='blur' objectFit='contain' alt='' />
            </Flex>
          </div>
        ))}
      </Slider>
    </Box>
  );
};

const ArrowSVG = () => (
  <svg width='8' height='16' viewBox='0 0 10 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M1 9L9 1' stroke='white' stroke-width='1.5' stroke-linecap='round' />
    <path d='M1 9L9 17' stroke='white' stroke-width='1.5' stroke-linecap='round' />
  </svg>
);
