import { Currency, ShippingDetails, PaymentProvider } from '@menesko/models-harmonia';
import { PaymentProvider as SharedPaymentProvider } from '@shared/payments';
import { useState } from 'react';

import { useAppLead } from './useAppLead';
import { usePaymentMutation } from './usePaymentMutation';
import { useProducts } from './useProducts';
import { useTracking } from './useTracking';

export const useCheckout = () => {
  const currency = 'USD';
  const [orderNumber, setOrderNumber] = useState<string>();
  const { id, selectedPlan, shippingDetails } = useAppLead();
  const { findProductById } = useProducts();
  const { checkoutPurchase, checkoutSubmit } = useTracking();
  const { mutateAsync } = usePaymentMutation();
  const price = selectedPlan?.prices?.find((price) => price.currency === currency);

  return {
    createdOrderNumber: orderNumber,
    selectedPlan,
    price: price?.price || 0,
    finalPrice: price?.finalPrice || 0,
    currency,
    createOrder: async (data: { paymentProvider: SharedPaymentProvider }) => {
      checkoutSubmit({ category: data.paymentProvider, label: data.paymentProvider });

      const response = await mutateAsync({
        leadId: String(id),
        productId: String(selectedPlan?.id),
        paymentProvider: data.paymentProvider as PaymentProvider,
        currency: Currency.USD,
        shippingDetails: shippingDetails as ShippingDetails,
      });

      setOrderNumber(response.orderNumber);

      return response;
    },
    trackPurchase: (productId: string, payload?: { method?: string }) => {
      const product = findProductById(productId);
      const price = product?.prices?.find((price) => price.currency === currency);
      const orderId = [id, productId].join('_');

      checkoutPurchase({
        orderId,
        currency,
        value: price?.finalPrice || 0,
        items: [
          {
            item_id: String(product?.key),
            item_name: String(product?.name),
          },
        ],
        purchaseType: 'initial',
        label: payload?.method,
      });
    },
  };
};
