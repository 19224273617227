import { useRouter } from 'next/router';

import { FUNNELS } from '~/constants';
import { Funnel } from '~/types';

interface Overrides {
  path?: string;
}

const getFunnelName = (path: string): Funnel => {
  const pathsList = path.substring(1).split('/');

  return Object.values(FUNNELS).includes(pathsList[0] as Funnel) ? (pathsList[0] as Funnel) : 'main';
};

export const useAppRouter = (nextRoute?: string) => {
  const router = useRouter();
  const asPathname = router.asPath.split('?')[0];
  const funnelName = getFunnelName(asPathname);

  const getNextFunnelRounte = (overrides?: Overrides) => {
    const path = overrides?.path || nextRoute || '/';

    return funnelName === FUNNELS.main ? path : `/${funnelName}${path}`;
  };

  return {
    ...router,
    pushNextRoute: (queryParams?: Record<string, string | undefined>) => {
      if (nextRoute) {
        router.push(queryParams ? { pathname: nextRoute, query: queryParams } : nextRoute);
      }
    },
    funnelName,
    asPathname,
    getNextFunnelRounte,
    pushNextFunnelRoute: (overrides?: Overrides) => {
      router.push(getNextFunnelRounte(overrides));
    },
    isLocaleDefault: router.locale === 'en-US',
    isPathCheckout: /\/checkout/.test(router.asPath),
    isPathPlans: /\/plans$/.test(router.asPath),
    isPathShipping: /\/shipping$/.test(router.asPath),
  };
};
