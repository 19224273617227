import { AppProps } from 'next/app';
import Head from 'next/head';

import { GlobalProvider } from '~/GlobalProvider';
import { useAppRouter } from '~/hooks/useAppRouter';

import { config } from '../config';

import '../global.css';

const SEO_PAGES = ['/'];

const MyApp = ({ Component, pageProps }: AppProps) => {
  const { asPath, isLocaleDefault } = useAppRouter();

  return (
    <>
      <GlobalProvider>
        <Component {...pageProps} />
      </GlobalProvider>

      <Head>
        {SEO_PAGES.includes(asPath) ? (
          <>{isLocaleDefault ? null : <link rel='canonical' href={`https://www.${config.DOMAIN}${asPath}`} />}</>
        ) : (
          <meta name='robots' content='noindex' />
        )}
        <title>{`${config.TITLE} | ${config.NAME}`}</title>
        <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' />
      </Head>
    </>
  );
};

export default MyApp;
