import { initPlasmicLoader } from '@plasmicapp/loader-nextjs';
import { Button } from '@shared/common';

import {
  CheckoutModal,
  EmailForm,
  FreeShippingBanner,
  GenderSelection,
  CortisolCocktailSelection,
  PcosPillsSelection,
  CortisolCocktailToggle,
  CalculationsLoader,
  SummaryStressParameters,
  WeightLossChart,
  WeightLossEstimationHeadline,
  WeightLossProofDescription,
  ProductGallery,
  ShippingModal,
  BadgesList,
  OrderDetails,
} from '~/components';
import { config } from '~/config';

export const PLASMIC = initPlasmicLoader({
  projects: [
    {
      id: config.PLASMIC.PROJECT_ID,
      token: config.PLASMIC.PROJECT_TOKEN,
    },
  ],
  preview: config.IS_DEV,
});

PLASMIC.registerComponent(Button, {
  name: 'Button',
  props: {
    children: {
      type: 'slot',
    },
    variant: {
      type: 'choice',
      options: ['primary', 'black'],
      defaultValue: 'primary',
    },
    action: {
      type: 'choice',
      options: ['navigate', 'scroll'],
      defaultValue: 'navigate',
    },
    value: {
      type: 'string',
    },
  },
});

PLASMIC.registerComponent(EmailForm, {
  name: 'EmailForm',
  props: {},
});

PLASMIC.registerComponent(CortisolCocktailSelection, {
  name: 'CortisolCocktailSelection',
  props: {},
});

PLASMIC.registerComponent(PcosPillsSelection, {
  name: 'PcosPillsSelection',
  props: {},
});

PLASMIC.registerComponent(CortisolCocktailToggle, {
  name: 'CortisolCocktailToggle',
  props: {},
});

PLASMIC.registerComponent(CheckoutModal, {
  name: 'CheckoutForm',
  props: {},
});

PLASMIC.registerComponent(GenderSelection, {
  name: 'GenderSelection',
  props: {},
});

PLASMIC.registerComponent(FreeShippingBanner, {
  name: 'FreeShippingBanner',
  props: {},
});

PLASMIC.registerComponent(SummaryStressParameters, {
  name: 'SummaryStressParameters',
  props: {},
});

PLASMIC.registerComponent(WeightLossChart, {
  name: 'WeightLossChart',
  props: {},
});

PLASMIC.registerComponent(WeightLossEstimationHeadline, {
  name: 'WeightLossEstimationHeadline',
  props: {},
});

PLASMIC.registerComponent(WeightLossProofDescription, {
  name: 'WeightLossProofDescription',
  props: {},
});

PLASMIC.registerComponent(CalculationsLoader, {
  name: 'CalculationsLoader',
  props: {},
});

PLASMIC.registerComponent(ProductGallery, {
  name: 'ProductGallery',
  props: {},
});

PLASMIC.registerComponent(ShippingModal, {
  name: 'ShippingForm',
  props: {},
});

PLASMIC.registerComponent(BadgesList, {
  name: 'BadgesList',
  props: {},
});

PLASMIC.registerComponent(OrderDetails, {
  name: 'OrderDetails',
  props: {},
});
